import { request } from '@@/exports';
// 会议账号管理

/**
 * 列表
 * @param params
 * @param options
 */
export async function getUserList(
  params: any,
  options?: any,
) {
  return request('/v1/net_meeting/relation/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/**
 * 查询会议账号总额
 * @param params
 */
export async function getUserTotal(
  params: any,
) {
  return request('/v1/net_meeting/relation/get_total_account', {
    method: 'GET',
    params: {
      ...params,
    },
  });
}

/**
 * 添加
 * @param params
 */
export async function addUser(
  params: any,
) {
  return request('/v1/net_meeting/relation/add_account', {
    method: 'POST',
    data: {
      ...params,
    },
  });
}

/**
 * 删除
 * @param params
 */
export async function deleteUser(
  params: number[],
) {
  return request('/v1/net_meeting/relation/delete_account', {
    method: 'POST',
    data: {
      ids: params
    },
  });
}
