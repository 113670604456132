import Api from '@/services/api';
import { Avatar } from '@dyrl/web';
import { useRequest } from 'ahooks';
import { Checkbox, Empty, Skeleton, Tag } from 'antd';
import { isEmpty } from 'lodash-es';
import { CheckedNodesType, SetCheckedNodesType } from './Selector';

type SearchResultProps = {
  keyword: string;
  checkedNodes: CheckedNodesType[];
  setCheckedNodes: SetCheckedNodesType;
};
const SearchResult = (props: SearchResultProps) => {
  const { keyword, setCheckedNodes, checkedNodes } = props;
  const { data, loading } = useRequest(
    () =>
      Api.netMeeting.user.getUserList({
        current: '1',
        pageSize: '100',
        member_name: keyword,
      } as any),
    {
      refreshDeps: [keyword],
    },
  );
  const checked = ({ member_id }: { member_id: string }) => {
    return checkedNodes.some((item) => item.member_id === member_id);
  };
  if (loading) return <Skeleton paragraph={{ rows: 7 }} />;
  if (isEmpty(data?.data))
    return (
      <div className={'flex flex-1 flex-col justify-center'}>
        <Empty />
      </div>
    );
  return (
    <div className={'flex-1 overflow-y-auto'}>
      {data?.data.map((item: any) => {
        return (
          <div key={item.id} className={'flex py-[5px] px-[15px]'}>
            <Checkbox
              checked={checked(item)}
              onChange={(e) => {
                setCheckedNodes(
                  [
                    {
                      member_id: item.member_id,
                      label: item.name,
                      value: item.member_id,
                      _original: item,
                    },
                  ],
                  e.target.checked,
                );
              }}
            >
              <div className={'flex gap-x-[5px] items-center'}>
                <Avatar size={30} src={item.member_avatar_url}>
                  {item.member_name}
                </Avatar>
                {item.member_name}
                <div>
                  <Tag color={'green'}>
                    {item.org_name}
                  </Tag>
                </div>
              </div>
            </Checkbox>
          </div>
        );
      })}
    </div>
  );
};

export default SearchResult;
