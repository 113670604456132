// @ts-ignore
/* eslint-disable */
// API 更新时间：
// API 唯一标识：
import * as yonghuguanli from './yonghuguanli';
import * as jiaoseguanli from './jiaoseguanli';
import * as wenjian from './wenjian';
import * as minganciguanli from './minganciguanli';
import * as liuchengyinqingdiceng from './liuchengyinqingdiceng';
import * as zidingyikuaijiecaidan from './zidingyikuaijiecaidan';
import * as jiaoshiduanWodebanji from './jiaoshiduanWodebanji';
import * as tongzhijilu from './tongzhijilu';
import * as gongwenguidang from './gongwenguidang';
import * as gongwenguidangmulu from './gongwenguidangmulu';
import * as gongwenguanli from './gongwenguanli';
import * as gongwenqianshoupaiming from './gongwenqianshoupaiming';
import * as gongwenyinzhang from './gongwenyinzhang';
import * as gongwentongjixiangguan from './gongwentongjixiangguan';
import * as gongwenliuzhuanliuchengdingyi from './gongwenliuzhuanliuchengdingyi';
import * as gongwenfenlei from './gongwenfenlei';
import * as gongwenliuchengguanli from './gongwenliuchengguanli';
import * as yonghuzhongxin from './yonghuzhongxin';
import * as yanzhengma from './yanzhengma';
import * as gongwenfujian from './gongwenfujian';
import * as xuexiaoguanli from './xuexiaoguanli';
import * as banjiguanli from './banjiguanli';
import * as nianjiguanli from './nianjiguanli';
import * as xueduan from './xueduan';
import * as xuenianxueqi from './xuenianxueqi';
import * as dequguanli from './dequguanli';
import * as shujuzidian from './shujuzidian';
import * as shujuquanxian from './shujuquanxian';
import * as xitongcaidan from './xitongcaidan';
import * as bumenchengyuanguanli from './bumenchengyuanguanli';
import * as bumenguanli from './bumenguanli';
import * as jiaxiao from './jiaxiao';
import * as xuesheng from './xuesheng';
import * as wenjuan from './wenjuan';
import * as zuzhi from './zuzhi';
import * as xitongxiaoxi from './xitongxiaoxi';
import * as gongwenguanliandanwei from './gongwenguanliandanwei';
import * as jiaoshi from './jiaoshi';
import * as fenlei from './fenlei';
import * as renyuanguanli from './renyuanguanli';
import * as changdeshezhi from './changdeshezhi';
import * as daochu from './daochu';
import * as huiyianpai from './huiyianpai';
import * as huiyishiguanli from './huiyishiguanli';
import * as huiyitongji from './huiyitongji';
import * as gongwenduixiangzu from './gongwenduixiangzu';
import * as xitonggengxin from './xitonggengxin';
import * as xitonggonggao from './xitonggonggao';
import * as xitonggonggaofenlei from './xitonggonggaofenlei';
import * as liuchengpeizhi from './liuchengpeizhi';
import * as liuchengshenpi from './liuchengshenpi';
import * as shujugailan from './shujugailan';
import * as xiazairenwu from './xiazairenwu';
import * as huikong from './huikong';
import * as duijieceshibuyaoguan from './duijieceshibuyaoguan';
import * as xuexiaotongzhi from './xuexiaotongzhi';
import * as huancun from './huancun';
import * as tongzhi from './tongzhi';
import * as tongjixiangguan from './tongjixiangguan';
import * as anquanjiaoyu from './anquanjiaoyu';
import * as lubo from './lubo';
import * as denglurenzheng from './denglurenzheng';
import * as weixin from './weixin';
import * as jiankongguanli from './jiankongguanli';
import * as jiankongquyuyingsheguanli from './jiankongquyuyingsheguanli';
import * as haikangweishi from './haikangweishi';
import * as shipinjiankong from './shipinjiankong';
import * as disanfangyingyong from './disanfangyingyong';
import * as shijianfuwu from './shijianfuwu';
import * as gongwenliuzhuanrizhi from './gongwenliuzhuanrizhi';
import * as wangyeshouquan from './wangyeshouquan';
import * as shouquanhuihua from './shouquanhuihua';
import * as gongwenwendang from './gongwenwendang';
import * as rizhi from './rizhi';
import * as xueshengguanli from './xueshengguanli';
import * as jiaoyududao from './jiaoyududao';
import * as dianziqianzhang from './dianziqianzhang';
import * as yinzhangguanli from './yinzhangguanli';
import * as caidanguanli from './caidanguanli';
import * as guanliyuan from './guanliyuan';
import * as linianshujutongji from './linianshujutongji';
import * as qianshu from './qianshu';
import * as xieyiguanli from './xieyiguanli';
import * as duanxinchi from './duanxinchi';
import * as jiankongfenleiguanli from './jiankongfenleiguanli';
import * as duanxinjilu from './duanxinjilu';
import * as xitonggonggaoyuedujilu from './xitonggonggaoyuedujilu';
import * as caozuoshouce from './caozuoshouce';
import * as gongwenguidangv2 from './gongwenguidangv2';
import * as tupian from './tupian';
import * as tupianhuoqu from './tupianhuoqu';
import * as xitong from './xitong';
import * as chengyuan from './chengyuan';
import * as bumenchengyuanbiaoqianguanli from './bumenchengyuanbiaoqianguanli';
import * as fuwuqicaokong from './fuwuqicaokong';
import * as appgengxin from './appgengxin';
import * as user from '../netMetting/user'

export default {
  yonghuguanli,
  jiaoseguanli,
  wenjian,
  minganciguanli,
  liuchengyinqingdiceng,
  zidingyikuaijiecaidan,
  jiaoshiduanWodebanji,
  tongzhijilu,
  gongwenguidang,
  gongwenguidangmulu,
  gongwenguanli,
  gongwenqianshoupaiming,
  gongwenyinzhang,
  gongwentongjixiangguan,
  gongwenliuzhuanliuchengdingyi,
  gongwenfenlei,
  gongwenliuchengguanli,
  yonghuzhongxin,
  yanzhengma,
  gongwenfujian,
  xuexiaoguanli,
  banjiguanli,
  nianjiguanli,
  xueduan,
  xuenianxueqi,
  dequguanli,
  shujuzidian,
  shujuquanxian,
  xitongcaidan,
  bumenchengyuanguanli,
  bumenguanli,
  jiaxiao,
  xuesheng,
  wenjuan,
  zuzhi,
  xitongxiaoxi,
  gongwenguanliandanwei,
  jiaoshi,
  fenlei,
  renyuanguanli,
  changdeshezhi,
  daochu,
  huiyianpai,
  huiyishiguanli,
  huiyitongji,
  gongwenduixiangzu,
  xitonggengxin,
  xitonggonggao,
  xitonggonggaofenlei,
  liuchengpeizhi,
  liuchengshenpi,
  shujugailan,
  xiazairenwu,
  huikong,
  duijieceshibuyaoguan,
  xuexiaotongzhi,
  huancun,
  tongzhi,
  tongjixiangguan,
  anquanjiaoyu,
  lubo,
  denglurenzheng,
  weixin,
  jiankongguanli,
  jiankongquyuyingsheguanli,
  haikangweishi,
  shipinjiankong,
  disanfangyingyong,
  shijianfuwu,
  gongwenliuzhuanrizhi,
  wangyeshouquan,
  shouquanhuihua,
  gongwenwendang,
  rizhi,
  xueshengguanli,
  jiaoyududao,
  dianziqianzhang,
  yinzhangguanli,
  caidanguanli,
  guanliyuan,
  linianshujutongji,
  qianshu,
  xieyiguanli,
  duanxinchi,
  jiankongfenleiguanli,
  duanxinjilu,
  xitonggonggaoyuedujilu,
  caozuoshouce,
  gongwenguidangv2,
  tupian,
  tupianhuoqu,
  xitong,
  chengyuan,
  bumenchengyuanbiaoqianguanli,
  fuwuqicaokong,
  appgengxin,
  netMeeting: {
    user,
  },
};
