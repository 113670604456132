import { Modal } from '@dyrl/web';
import { Button } from 'antd';
import Selector, { CheckedNodesType } from './Selector';

type openProps = {
  title?: string;
  value?: React.Key[];
  // 0:教育局、1:学校
  orgType?: ('0' | '1')[];
  multiple?: boolean;
  onChange?: (checkedNodes: CheckedNodesType[]) => void;
  onConfirm?: (checkedNodes: CheckedNodesType[]) => void;
};
const open = (props: openProps) => {
  const checkedNodesRef: { current: CheckedNodesType[] } = {
    current: [],
  };
  const handler = Modal.open({
    title: props.title,
    width: 950,
    children: (
      <>
        <Modal.Body>
          <Selector
            value={props.value}
            multiple={props.multiple}
            onChange={(checkedNodes) => {
              checkedNodesRef.current = checkedNodes;
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handler.close()}>关闭</Button>
          <Button
            type={'primary'}
            onClick={() => {
              props.onConfirm?.(checkedNodesRef.current);
              handler.close();
            }}
          >
            确定
          </Button>
        </Modal.Footer>
      </>
    ),
  });
};
export default open;
