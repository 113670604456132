import Api from '@/services/api';
import { CloseOutlined } from '@ant-design/icons';
import { Avatar } from '@dyrl/web';
import { useRequest } from 'ahooks';
import { Skeleton, Tag } from 'antd';
import { isEmpty } from 'lodash-es';
import { v4 as uuidV4 } from 'uuid';
import { CheckedNodesType, SetCheckedNodesType } from './Selector';

type MemberProps = {
  value?: React.Key[];
  checkedNodes: CheckedNodesType[];
  setCheckedNodes: SetCheckedNodesType;
};
const Member = (props: MemberProps) => {
  const { checkedNodes, setCheckedNodes } = props;
  const { loading } = useRequest(
    () =>
    Api.netMeeting.user.getUserList({
      pageSize: props.value?.length,
      ids: props.value,
    } as any),
    {
      ready: !isEmpty(props.value),
      onSuccess: (data) => {
        const checkedNodes = data?.data.map((item: any) => {
          return {
            id: item.member_id,
            value: uuidV4(),
            label: item.nickname,
            isMember: true,
            _original: item,
          };
        });
        setCheckedNodes(checkedNodes);
      },
    },
  );
  return (
    <div className={'flex flex-col flex-auto overflow-hidden'}>
      <div
        style={{ borderBottom: '1px solid #f0f0f0' }}
        className={
          'flex justify-between items-center h-[45px] px-[15px] min-h-[45px]'
        }
      >
        已选：{checkedNodes.length}名成员
        <a onClick={() => setCheckedNodes([])}>清空</a>
      </div>
      {loading ? (
        <div className={'p-[15px]'}>
          <Skeleton active paragraph={{ rows: 7 }} />
        </div>
      ) : (
        <div className={'flex-auto overflow-y-auto'}>
          {checkedNodes.map((item) => {
            const { _original } = item;
            return (
              <div
                key={_original.member_id}
                className={'flex justify-between py-[5px] px-[15px]'}
              >
                <div className={'flex gap-x-[5px] items-center'}>
                  <Avatar size={30} src={_original.member_avatar_url}>
                    {_original.member_name}
                  </Avatar>
                  {_original.member_name}
                  <Tag>{_original.org_name}</Tag>
                </div>
                <div
                  className={'cursor-pointer flex items-center'}
                  onClick={() => setCheckedNodes([item], false)}
                >
                  <CloseOutlined />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Member;
